/**
 * <Button variant={Variant} intent={Intent} /> design system foundation.
 *
 * This should replace all other Button’s currently in the application as it
 * provides the shared terminology that we have between product and engineering.
 *
 * View the `design-system/button` story in storybook to see all variantions.
 *
 * Note that we store all variations as jest snapshot tests so any change will
 * require an explicit test update and confirmation in code review.
 *
 *
 * Future efforts include:
 * * Formalizing Icon layouts
 * * `actions` prop for [ Text |▼] buttons
 * * <ButtonGroup />
 */

import styled from 'styled-components';
import * as brandColors from '../theme/colors/brand';

export enum Intent {
  Primary, // default
  Secondary,
  Tertiary,
  Danger,
}

export enum Variant {
  Standard, // default
  Outline,
  Text,
  Pill,
}

interface Props {
  intent?: Intent;
  variant?: Variant;
  disabled?: boolean;
  loading?: boolean;
}

export const Button = styled.button<Props>`
  display: inline-block;
  border-radius: 3px;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-radius: ${borderRadius};
  border-color: ${border};
  background-color: ${background};
  color: ${color};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: ${padding};
  transition: ease-in 0.2s box-shadow;
  text-decoration: none;

  :focus,
  :hover {
    box-shadow: ${({ disabled }) =>
      disabled ? '0' : '0 2px 6px rgba(0, 0, 0, 0.3)'};
    color: ${color};
    text-decoration: none;
  }

  :active {
    box-shadow: ${({ disabled }) =>
      disabled ? '0' : 'inset 0 1px 5px rgba(0, 0, 0, 0.2)'};
    color: ${color};
    text-decoration: none;
  }
  [disabled] {
    cursor: not-allowed;
  }

  // bootstrap has a global anchor styles we need to override
  a {
    color: inherit;
  }
`;

function borderRadius({ /*intent,*/ variant }: Props) {
  switch (variant) {
    case Variant.Pill:
      return '2em';
    case Variant.Text:
    case Variant.Outline:
    case Variant.Standard:
    default:
      return '5px';
  }
}
function border({ intent, variant }: Props) {
  if (variant === Variant.Text) {
    return 'transparent';
  }
  switch (intent) {
    case Intent.Danger:
      return danger;
    case Intent.Tertiary:
      return tertiary;
    case Intent.Secondary:
      return secondary;
    case Intent.Primary:
    default:
      return primary;
  }
}
function background({ intent, variant, disabled }: Props) {
  if (variant === Variant.Text) {
    return 'transparent';
  } else if (variant === Variant.Outline) {
    return white;
  }
  switch (intent) {
    case Intent.Tertiary:
      return disabled ? ltertiary : tertiary;
    case Intent.Secondary:
      return disabled ? lsecondary : secondary;
    case Intent.Danger:
      return disabled ? ldanger : danger;
    case Intent.Primary:
    default:
      return disabled ? lprimary : primary;
  }
}

function color({ intent, variant, disabled }: Props) {
  switch (intent) {
    case Intent.Tertiary:
      if (variant === Variant.Outline || variant === Variant.Text) {
        return disabled ? ltertiary : tertiary;
      }
      return white;
    case Intent.Secondary:
      if (variant === Variant.Outline || variant === Variant.Text) {
        return disabled ? lsecondary : secondary;
      }
      return white;
    case Intent.Danger: {
      if (variant === Variant.Outline || variant === Variant.Text) {
        return disabled ? ldanger : danger;
      }
      return white;
    }
    case Intent.Primary:
    default:
      if (variant === Variant.Outline || variant === Variant.Text) {
        return disabled ? lprimary : primary;
      }
      return white;
  }
}

function padding({ variant }: Props) {
  if (variant === Variant.Pill) {
    return '0.5em 1.125em';
  } else {
    return '0.5em 0.875em';
  }
}
const primary = '#00BECB';
const secondary = brandColors.primary;
const tertiary = 'rgb(134, 135, 146)';
const white = '#fff';
const danger = brandColors.danger;

// Lightened by 40%
const lprimary = '#299ba2';
const lsecondary = '#60536a;';
const ltertiary = '#8c8c8c;';
const ldanger = '#de7c7c';
