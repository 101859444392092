/**
 * <HStack /> and <VStack /> design system foundation.
 *
 * This should enable the vast majority of our layout foundations in a systemic
 * and shared way.
 *
 * View the `design-system/stack` story in storybook to see example usage and
 * documentation.
 *
 * Note that we store all variations as jest snapshot tests so any change will
 * require an explicit test update and confirmation in code review.
 *
 * Future efforts may include:
 * * Limiting spacing options to a scaling number rather than arbitrary values.
 */

import styled from 'styled-components';

interface Props {
  $spacing?: number | string;
  'align-items'?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  'justify-content'?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  'flex-wrap'?:
    | 'nowrap'
    | 'wrap'
    | 'wrap-reverse'
    | 'initial'
    | 'inherit'
    | 'unset';
}

const defaultSpacing = '1rem';
function spacing({ $spacing }: Props) {
  switch (typeof $spacing) {
    case 'number':
      return `${$spacing}px`;
    case 'string':
      return $spacing;
    default:
      return defaultSpacing;
  }
}

function flexWrap(props: Props) {
  return props['flex-wrap'] ? props['flex-wrap'] : 'nowrap';
}
function alignItems(props: Props) {
  return props['align-items'] ? props['align-items'] : 'stretch';
}
function justifyContent(props: Props) {
  return props['justify-content'] ? props['justify-content'] : 'flex-start';
}
export const VStack = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: ${spacing};
  align-items: ${alignItems};
  justify-content: ${justifyContent};

  /* VStack child spacing is controlled by the VStack, so wipe out any set margins */
  > * {
    margin: 0;
  }
`;

export const HStack = styled.div<Props>`
  display: flex;
  flex-direction: row;
  gap: ${spacing};
  align-items: ${alignItems};
  justify-content: ${justifyContent};
  flex-wrap: ${flexWrap};

  /* HStack child spacing is controlled by the VStack, so wipe out any set margins */
  > * {
    margin: 0;
  }
`;
